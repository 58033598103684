<template>
  <div class="Userhl_favideo">
    <div class="fxiangmu_top">
      <p class="videohejititle">选择一个合集发布视频</p>
      <ul class="videoul">
        <li class="videoulli" @click="addheji">
          <div class="videoulli_xinjian">新建合集</div>
        </li>
        <li
          class="videoulli"
          v-for="(item, index) in hejiall"
          :key="index"
          @click="addvideoall(item.p_id, item.p_type)"
        >
          <img :src="item.title_pic" alt="" />
          <p>{{ item.title }}</p>
          <p>
            <span v-if="item.p_type == 22">环境设计</span>
            <span v-if="item.p_type == 23">产品设计</span>
            <span v-if="item.p_type == 24">视觉传达设计</span>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      hejiall: [],
      userinfo: {},
    };
  },
  methods: {
    addheji() {
      this.$router.push({ path: "/Userhl_favideohj" });
    },
    addvideoall(id, typeid) {
      this.$router.push({
        path: "/Userhl_favideo_s",
        query: { hejiid: id, typeid: typeid },
      });
    },
  },
  created() {
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.$axios({
      headers: { "X-CSRF-TOKEN": that.userinfo.token },
      url: "/api/getMentorVideoList",
      method: "post",
      data: {
        page: "1",
        limit: "100",
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.hejiall = res.data.result.list;
      }
    });
  },
};
</script>
